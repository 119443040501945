<template>
  <div class="share" v-if="isShowPage">
    <div class="flex flex-left align-center share-one">
      <img src="../../assets/img/share/notice.png" alt="">如有需要可以复制给予的参考文案，在分享后黏贴给好友
    </div>
    <div class="share-two">
      <div class="share-two-info">
        一条视频，让一个宠物淘宝店铺月营业额200多万。
      </div>
      <div class="share-two-info">
        一条视频，让一款普通牛肉酱店铺日人流量7万+，日成交额90万。
      </div>
      <div class="share-two-info">
        一条视频，让一个个景区，一座座城市焕发了新的生命力。
      </div>
      <div class="share-two-info" style="margin-top: 6px;">
        流媒体让素人成为红人！让老店焕发生机！在这一切的背后隐藏着怎样的秘密？
      </div>
      <div class="share-two-info" style="margin-top: 6px;">
        当营销手段从图文走向视频，营销范围从公域走向私域；营销策略从单一走向矩阵；营销表达从品牌走向内容。外部环境的变化给各行各业带来了新的挑战和机会。
      </div>
      <div class="share-two-info">
        以抖音为主要介质的短视频营销正在兴起。爆款短视频该如何打造？如何构建系统化的短视频内容体系？
      </div>
      <div class="share-two-info">
        最近爆火的私域带货的流程与套路有哪些？
      </div>
      <div class="share-two-info share-two-info1">
        答案尽在<span>《流媒体营销密码与私域带货实战课程套装》</span>之中
      </div>
      <!-- <div class="flex flex-center align-center share-two-radio">
        <van-checkbox v-model="checkedS" checked-color="#E62129">文案包含邀请码</van-checkbox>
      </div> -->
      <div class="share-two-btn">
        <van-button color="#E62129" plain type="default" style="height: 100%;" round @click="copyFun()">复制文案</van-button>
      </div>
    </div>
    <!-- <div class="flex flex-left align-center share-three">
      <span></span>
      分享图片
      <span></span>
    </div> -->
    <!-- <van-checkbox-group v-model="checkedArr">
      <ul class="flex flex-between share-four">
        <li class="share-four-li" v-for="item in 3" :key="item.id">
          <div class="share-four-li-img"></div>
          <div class="flex flex-between align-center share-four-li-info">
            <div class="share-four-li-info-l">
              意大利意大利莫斯卡意大利意大利莫斯卡
            </div>
            <div class="share-four-li-info-r">
              ¥<span>234</span>
            </div>
          </div>
          <van-checkbox class="share-four-li-check" name="a" checked-color="#E62129"></van-checkbox>
        </li>
      </ul>
    </van-checkbox-group> -->
    <div class="share-five">
      <div class="flex flex-center align-center share-five-title">
        <span></span>
        分享至
        <span></span>
      </div>
      <div class="flex flex-around share-five-main">
        <div class="flex flex-center flex-wrap share-five-main-list" @click="showHb = true;">
          <img src="../../assets/img/share/sc.png" alt="">
          <p>生成海报</p>
        </div>
        <div class="flex flex-center flex-wrap share-five-main-list" @click="getWxConfigFunc">
          <img src="../../assets/img/share/wx.png" alt="">
          <p>微信</p>
        </div>
        <!-- <div class="flex flex-center flex-wrap share-five-main-list" @click="getWxConfigFunc">
          <img src="../../assets/img/share/qq.png" alt="">
          <p>QQ</p>
        </div>
        <div class="flex flex-center flex-wrap share-five-main-list" @click="getWxConfigFunc">
          <img src="../../assets/img/share/wx-f.png" alt="">
          <p>朋友圈</p>
        </div>
        <div class="flex flex-center flex-wrap share-five-main-list" @click="getWxConfigFunc">
          <img src="../../assets/img/share/qq-f.png" alt="">
          <p>QQ空间</p>
        </div> -->
      </div>
    </div>
    <!-- 去朋友圈分享 -->
    <van-popup v-model:show="showWxf" round>
      <div class="share-wxf">
        <div class="flex flex-center align-center share-wxf-title">
          <span></span>
          将好物分享给身边人
          <span></span>
        </div>
        <div class="flex flex-center align-center share-wxf-msg">
          <img src="../../assets/img/share/share-wxf.png" alt="">
          分享文案已自动复制
        </div>
        <div class="flex flex-between share-wxf-btn">
          <van-button class="share-wxf-btn-list" plain type="default" color="#999999" round @click="showWxf = false;">取消</van-button>
          <van-button class="share-wxf-btn-list" type="default" color="#E62129" round>打开微信</van-button>
        </div>
      </div>
    </van-popup>
    <!-- 海报 -->
    <van-popup v-model:show="showHb" class="hbBox" round>
      <div class="qrimg">
        <img :src="qrImg" alt="">
      </div>
      <div class="flex flex-center align-center qrimg-btn">
        长按保存海报
        <img class="qrimg-btn-img" :src="qrImg" alt="">  
      </div>
    </van-popup>
    <!-- 分享弹层 -->
    <div class="share-fix" v-show="isShareToast">
      <div class="share-fix-line" :style="{background: 'url('+require('../../assets/img/goods/share-icon1.png')+')no-repeat center center',backgroundSize: 'cover'}"></div>
      <div class="flex flex-left share-fix-dec">
        <img src="../../assets/img/goods/share-icon2.png" alt="" class="share-fix-dec-icon">
        <div class="share-fix-dec-content">
          请点击右上角的“...”<br/>
          分享商品给你的身边人
        </div>
      </div>
      <div class="share-fix-btn" :style="{background: 'url('+require('../../assets/img/goods/share-icon3.png')+')no-repeat center center',backgroundSize: 'cover'}" @click="isShareToast = false;">我知道啦</div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style>
.hbBox{
    background: transparent !important;
  }
</style>
<style lang="scss" scoped>
  @import "./index.scss";
  .qrimg{
    width: 320px;
    height: 542px;
    border-radius: 10px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      overflow: hidden;
    }
    &-btn{
      position: relative;
      width: 320px;
      height: 44px;
      margin-top: 10px;;
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      border-radius: 44px;
      background-color: #E62129;
      &-img{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        overflow: hidden;
        opacity: 0;
      }
    }
  }
</style>