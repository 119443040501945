import {
    wxShareServiceGoodsPicture
} from '@/utils/goods.js';
import { weichatTools } from '@/utils/weichatShare.js';
import useClipboard from "vue-clipboard3"
export default {
    data() {
        return {
            isShowPage: true,
            checkedS: '',
            checkedArr: [],
            showWxf: false,
            goodsId: '',
            goodsInfo: '',
            showHb: false,
            qrImg: '',
            isShareToast: false
        }
    },
    created() {
        this.goodsId = this.$route.query.goodsId;
        this.shareGoodsFunc();
    },
    methods: {
        // 分享
        shareGoodsFunc() {
            let data = {
                memberId: this.$cookies.get('memberId')
            }
            wxShareServiceGoodsPicture(data).then(res => {
                console.log('分享', res)
                this.qrImg = res.data.pictureEncode;
                weichatTools.share.shareFunc('流媒体营销密码与私域带货实战课程套装', '灵龙惠生活“理性种草寻好货”', '', this.qrImg);
            })
        },
        // 复制订单号
        async copyFun() {
            try {
                const { toClipboard } = useClipboard()
                let msg = '一条视频，让一个宠物淘宝店铺月营业额200多万。\n一条视频，让一款普通牛肉酱店铺日人流量7万+，日成交额90万。\n一条视频，让一个个景区，一座座城市焕发了新的生命力。\n流媒体让素人成为红人！让老店焕发生机！在这一切的背后隐藏着怎样的秘密？\n当营销手段从图文走向视频，营销范围从公域走向私域；营销策略从单一走向矩阵；营销表达从品牌走向内容。外部环境的变化给各行各业带来了新的挑战和机会。\n以抖音为主要介质的短视频营销正在兴起。爆款短视频该如何打造？如何构建系统化的短视频内容体系？\n最近爆火的私域带货的流程与套路有哪些？\n答案尽在《流媒体营销密码与私域带货实战课程套装》之中';
                await toClipboard(msg)
                this.showWxf = true;
            } catch (e) {
                console.error(e)
            }
        },
        // 获取微信配置信息
        getWxConfigFunc() {
            this.isShareToast = true;
        }
    },
}